<template>
  <div class="bodyMeasurementsHeight">
    <base-heading
      type="2"
      modifiers="centerDesktop"
      class="bodyMeasurementsHeight__title"
    >
      {{ $t('bodyMeasurementsHeightModal.title') }}
    </base-heading>

    <div class="bodyMeasurementsHeight__body">
      {{ $t('bodyMeasurementsHeightModal.body') }}
    </div>

    <measurement-slider
      unit="cm"
      v-bind:min="0"
      v-bind:max="250"
      v-bind:label="$t('bodyMeasurementsHeightModal.label')"
      v-model="selectedHeight"
      class="bodyMeasurementsHeight__slider"
    >
      <font-awesome-icon
        v-bind:icon="['fas', 'ruler-vertical']"
        class="bodyMeasurementsHeight__sliderIcon"
      />
    </measurement-slider>

    <base-alert
      v-if="submitError"
      class="bodyMeasurementsHeight__alert"
    >
      {{ submitError }}
    </base-alert>

    <div
      v-if="showMedicalConsent"
      class="bodyMeasurementsHeight__consentForm"
    >
      <checkbox-toggle v-model="medical_consent" />

      <div class="bodyMeasurementsHeight__consentLabel">
        {{ $t('bodyMeasurementsHeightModal.medicalConsentLabel') }}
      </div>
    </div>

    <base-button
      modifiers="primary block fullWidth"
      v-bind:show-spinner="isSaving"
      v-bind:disabled="!medical_consent || !!isSaving"
      loading-text="Aan het opslaan"
      v-on:click="onSaveBodyMeasurements"
    >
      {{ $t('bodyMeasurementsHeightModal.saveButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Form } from 'vee-validate';
import BaseAlert from '@/components/BaseAlert';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import MeasurementSlider from '@/components/MeasurementSlider';
import CheckboxToggle from '@/components/CheckboxToggle';

export default {
  components: {
    Form,
    BaseAlert,
    BaseHeading,
    BaseButton,
    MeasurementSlider,
    CheckboxToggle,
  },

  props: {
    selectedDate: {
      type: Date,
      required: true,
    },

    selectedPeriodType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      submitError: '',
      selectedHeight: 170,
      showMedicalConsent: false,
    };
  },

  computed: {
    ...mapState('member', ['member', 'body', 'privacySettings']),

    medical_consent: {
      get() {
        return this.privacySettings.medical_consent;
      },
      async set(value) {
        await this.updatePrivacySettings({ medical_consent: value })
          .catch(() => this.medical_consent = !value);
      },
    },
  },

  created() {
    this.selectedHeight = this.body.height || this.selectedHeight;
    this.showMedicalConsent = !this.medical_consent;
  },

  methods: {
    ...mapActions('member', ['updatePrivacySettings', 'updateBody', 'fetchBody']),

    async onSaveBodyMeasurements() {
      this.isSaving = true;

      try {
        await this.updateBody({ height: this.selectedHeight });
        await this.fetchBody({
          year: this.selectedDate.getFullYear(),
          month: this.selectedPeriodType === 'month' ? this.selectedDate.getMonth() + 1 : null,
        });
      } catch (err) {
        if (err) {
          this.submitError = err.message;
        } else {
          this.submitError = this.$t('common.oopsTryAgain');
        }
      } finally {
        this.isSaving = false;

        if (!this.submitError) {
          this.$emit('close');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.bodyMeasurementsHeight__body {
  @include small;
  margin: 0 0 2rem 0;

  @include desktop {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

.bodyMeasurementsHeight__slider {
  margin: 0 0 rem(48px) 0;
}

.bodyMeasurementsHeight__alert {
  margin: 0 0 1rem 0;
}

.bodyMeasurementsHeight__consentForm {
  display: flex;
  margin: 0 0 rem(20px) 0;
}

.bodyMeasurementsHeight__consentLabel {
  @include label;
  margin: 0 0 0 rem(12px);
}
</style>
